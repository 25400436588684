import { useEffect, useState, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import { array, func, object, bool, string } from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

import { browser } from 'dibs-client-check';
import { HeaderFooterServerVarsContext } from '../HeaderFooterServerVarsContext/HeaderFooterServerVarsContext';
import MobileNavHeader from './MobileNavHeader/MobileNavHeader';
import { MobileNavSearchBar } from './MobileNavSearchBar/MobileNavSearchBar';
import { MobileAppBanner } from '../MobileAppBanner/MobileAppBanner';
import { shouldPromptAppBanner } from '../MobileAppBanner/helpers';
import { MobileNavAnimationSlide } from './MobileNavAnimations/MobileNavAnimationSlide';
import MobileNavMenu from './MobileNavMenu/MobileNavMenu';
import ScrollLock from './ScrollLock';
import MobileNavDrawer from './MobileNavDrawer/MobileNavDrawer';
import { MessageBannerLazy } from '../MessageBanner/MessageBannerLazy';

import { actionCreators } from '../../actions';
import { toggleAppBanner as toggleAppBannerAction } from '../../actions/appBannerActions';
import {
    getCurrencyPreference,
    getSessionCurrency,
    setSessionCurrency,
    setLocalCurrency,
} from '../../utils/preferredCurrency';
import { PriceIntlHandler } from '../PriceIntlHandler/PriceIntlHandler';

import styles from './mobileNav.scss';

const MOBILE_NAV_OPEN = 'open';
const MOBILE_NAV_CLOSE = 'close';
const MOBILE_NAV_NONE = 'none';

export const MobileNavComponent = props => {
    const { isTablet } = useContext(HeaderFooterServerVarsContext);

    const [animationType, setAnimationType] = useState(MOBILE_NAV_NONE);
    const [scrollLock, setScrollLock] = useState(false);
    const [shouldRepaint, setTriggerRepaint] = useState(false);
    const [onlySearchBarIsVisible, setOnlySearchBarIsVisible] = useState(false);
    const { isClient, disableStickyNav, showMarketingMessages } = props;
    // Tablets are using mobile navigation, this banner should be visible only on tablets
    const isBannerVisible = showMarketingMessages && !disableStickyNav && isTablet;

    const {
        onAuthClick,
        viewer,
        user,
        regionalInfo,
        isMobile,
        isMobileMenuOpen,
        isAppBannerVisible,
        hideHeader,
        dispatch,
        populateCurrency,
        hideSearchBar,
        userId,
        relayEnvironment,
        hasRootPendingRefetch,
        toggleAppBanner,
    } = props;
    const userCurrency = user?.preferences?.currency;

    useEffect(() => {
        /**
         * Need to hide MobileAppBanner on Safari browser, if user has mobile app,
         * because Universal Links is showing inbuilt banner on top of the page.
         */
        const hasLoggedInWithApp = !(userId && user === null) && !user?.hasLoggedInWithApp;
        dispatch(
            toggleAppBanner(shouldPromptAppBanner() && (!browser.safari() || hasLoggedInWithApp))
        );
    }, [dispatch, toggleAppBanner, userId, user]);

    const regionalCurrency = regionalInfo?.[0]?.currency;
    const onHeaderActionClick = useCallback(() => {
        dispatch(actionCreators.toggleMobileMenu(!isMobileMenuOpen));
        if (scrollLock) {
            setScrollLock(false);
        }
        setAnimationType(MOBILE_NAV_OPEN);
    }, [dispatch, setScrollLock, scrollLock, setAnimationType, isMobileMenuOpen]);

    const onAnimationEnd = useCallback(() => {
        if (!isMobileMenuOpen) {
            if (scrollLock) {
                setScrollLock(false);
            }
            setAnimationType(MOBILE_NAV_CLOSE);
        } else {
            setScrollLock(true);
        }
    }, [isMobileMenuOpen, setAnimationType, scrollLock]);

    const onDrillDownTransitionEnd = useCallback(
        isDrilldownOpen => {
            setTriggerRepaint(isDrilldownOpen);
        },
        [setTriggerRepaint]
    );

    useEffect(() => {
        if (!user && !getSessionCurrency()) {
            setSessionCurrency(regionalCurrency);
        } else if (userCurrency) {
            setLocalCurrency(userCurrency);
        }
        populateCurrency(getCurrencyPreference({ user, regionalCurrency }));
    }, [user, populateCurrency, userCurrency, regionalCurrency]);

    const isFullWidth = isMobile;
    const isDrawerDisabled = disableStickyNav || isMobileMenuOpen;
    const drawerClass = classnames(styles.container, {
        [styles.isOpen]: animationType === MOBILE_NAV_OPEN,
        [styles.isFullWidth]: isFullWidth,
        [styles.disableStickyNav]: disableStickyNav,
    });

    const onlyShowSearchBar = onlySearchBarIsVisible && !isTablet;

    const spacerClass = classnames(styles.spacer, {
        [styles.isFullWidth]: isFullWidth,
        [styles.isWithoutSearchBar]: hideSearchBar,
        [styles.isWithAppBanner]: isAppBannerVisible,
        [styles.onlyShowSearchBar]: onlyShowSearchBar,
        [styles.hasMessageBanner]: isBannerVisible,
    });

    return (
        <header hidden={hideHeader}>
            <div className={spacerClass}>
                <MobileNavDrawer className={drawerClass} disabled={isDrawerDisabled}>
                    {/* Tablets are using mobile navigation, this banner should be visible only on tablets */}
                    {showMarketingMessages && isTablet && (
                        <MessageBannerLazy
                            hasRootPendingRefetch={hasRootPendingRefetch}
                            relayEnvironment={relayEnvironment}
                            userId={userId}
                            hasUserId={!!userId}
                            isClient={isClient}
                        />
                    )}
                    {isClient && !onlyShowSearchBar && isAppBannerVisible && <MobileAppBanner />}
                    <MobileNavHeader
                        viewer={viewer}
                        user={user}
                        isMobileMenuOpen={isMobileMenuOpen}
                        onActionClick={onHeaderActionClick}
                        containerClassName={classnames({
                            [styles.spacer]: !isFullWidth,
                            [styles.whenSearchBarHidden]: hideSearchBar,
                        })}
                        showUnreadMessageCount={!(isMobileMenuOpen && isFullWidth)}
                    />
                    {!hideSearchBar && (
                        <MobileNavSearchBar
                            isBelowAppBanner={isAppBannerVisible}
                            isAnimatedSearchBar={!isFullWidth}
                            showScrollLockedOverlay={isFullWidth}
                            focusOnOverlayScroll={isFullWidth}
                            showArrow={isFullWidth && !onlyShowSearchBar}
                            onlySearchBarIsVisible={onlySearchBarIsVisible}
                            setOnlySearchBarIsVisible={setOnlySearchBarIsVisible}
                            isClient={isClient}
                            viewer={viewer}
                        />
                    )}
                    <ScrollLock isLocked={scrollLock}>
                        <MobileNavAnimationSlide
                            onTransitionEnd={onAnimationEnd}
                            show={isMobileMenuOpen}
                            isFullWidth={isFullWidth}
                            topLevel
                            shouldRepaint={shouldRepaint}
                        >
                            <MobileNavMenu
                                onTransitionEnd={onDrillDownTransitionEnd}
                                onAuthClick={onAuthClick}
                                viewer={viewer}
                                user={user}
                                isFullWidth={isFullWidth}
                                isAppBannerVisible={isAppBannerVisible}
                            />
                        </MobileNavAnimationSlide>
                    </ScrollLock>
                </MobileNavDrawer>
            </div>
            <PriceIntlHandler />
        </header>
    );
};

function mapStateToProps({ header, appBanner }) {
    return {
        disableStickyNav: header.disableStickyNav,
        isMobileMenuOpen: header.isMobileMenuOpen,
        isMobile: header.isMobile,
        isAppBannerVisible: appBanner.isAppBannerVisible,
        hideHeader: header.hideHeader,
        showMarketingMessages: header.showMarketingMessages,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        populateCurrency: currency => dispatch(actionCreators.populateCurrency(currency)),
        toggleAppBanner: payload => dispatch(toggleAppBannerAction(payload)),
        dispatch,
    };
};

export const MobileNav = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(MobileNavComponent),
    {
        viewer: graphql`
            fragment MobileNav_viewer on Viewer {
                ...MobileNavHeader_viewer
                ...MobileNavMenu_viewer
                ...MobileNavSearchBar_viewer
            }
        `,
        user: graphql`
            fragment MobileNav_user on User {
                hasLoggedInWithApp: hasLoggedInWithDeviceType(deviceType: IOS_APP)
                preferences {
                    currency
                }
                ...MobileNavMenu_user
                ...MobileNavHeader_user
            }
        `,
        regionalInfo: graphql`
            fragment MobileNav_regionalInfo on RegionalInfo @relay(plural: true) {
                currency
            }
        `,
    }
);

MobileNavComponent.defaultProps = {
    isAppBannerVisible: false,
};

MobileNavComponent.propTypes = {
    viewer: object.isRequired,
    user: object,
    onAuthClick: func.isRequired,
    fetchRegionalInfo: bool,
    isClient: bool,
    isMobile: bool,
    isMobileMenuOpen: bool,
    dispatch: func.isRequired,
    hideSearchBar: bool,
    userIds: array,
    populateCurrency: func,
    toggleAppBanner: func,
    isAppBannerVisible: bool,
    hideHeader: bool,
    disableStickyNav: bool,
    userId: string,
    relayEnvironment: object,
    hasRootPendingRefetch: bool,
    showMarketingMessages: bool,
    regionalInfo: array,
};
