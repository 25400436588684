import { type FC } from 'react';
import classNames from 'classnames';
import ShoppingBagIcon from 'dibs-icons/exports/legacy/ShoppingBag';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import styles from './mobileNavIcons.scss';
import MobileNavDisplayAmount from '../MobileNavDisplayAmount/MobileNavDisplayAmount';

// The maximum amount of cart items to display. We just append a plus sign if it's bigger than this number.
const MAX_CART_COUNT = 9;
const URL_PATH_CART = '/my/cart/';

type Props = { cartItemCount: number };

const MobileNavCart: FC<Props> = ({ cartItemCount }) => {
    const iconClassName = classNames(styles.icon, styles.bagIcon);
    const countClassName = classNames(styles.count, {
        [styles.maxCount]: cartItemCount > MAX_CART_COUNT,
    });

    return (
        <SeoLink linkData={{ path: URL_PATH_CART }} className={styles.container} dataTn="cart-icon">
            {/* add label */}
            {!!cartItemCount && (
                <MobileNavDisplayAmount
                    amount={cartItemCount}
                    className={countClassName}
                    max={MAX_CART_COUNT}
                />
            )}
            <ShoppingBagIcon className={iconClassName} />
        </SeoLink>
    );
};

export default MobileNavCart;
