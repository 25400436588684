import serverVars from 'server-vars';
import { PAGE_TYPE, BROWSE_TYPES } from 'dibs-constants/exports/pageTypes';
import { trackEvent } from 'dibs-tracking';
import { mobile } from 'dibs-client-check';
import { APP_BANNER_STORAGE_KEY } from './constants';

export const shouldPromptAppBanner = (): boolean => {
    const hasNoNotifParam =
        new URLSearchParams(document.location.search).get('no-notif') === 'true';
    const hasClosedBanner = !!sessionStorage.getItem(APP_BANNER_STORAGE_KEY);
    const allowedPageTypes = [PAGE_TYPE.HOME, PAGE_TYPE.PDP, ...BROWSE_TYPES];
    const pageType = serverVars.get('settings.pageType');
    const isAllowedPageType = allowedPageTypes.includes(pageType);
    return isAllowedPageType && !hasNoNotifParam && !hasClosedBanner && mobile.iPhone();
};

export const mobileAppBannerTracking = (action: string): void => {
    trackEvent({
        category: 'promo interaction',
        label: 'open',
        action: `mobile app banner ${action}`,
    });
};
