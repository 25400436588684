import { Component } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { bool, func, object } from 'prop-types';
import classnames from 'classnames';

import { IntlContext, defineMessages } from 'dibs-react-intl';
import { isEmailOnly } from 'dibs-cookie-jar/exports/isEmailOnly';

import MobileNavDrilldown from '../MobileNavDrilldown/MobileNavDrilldown';
import { MobileNavAnimationSlide } from '../MobileNavAnimations/MobileNavAnimationSlide';
import { LINK_ELEMENT_TYPE_ANCHOR } from '../MobileNavDrilldown/MobileNavDrilldownItem';
import MobileNavMenuBody from './MobileNavMenuBody';

import styles from './mobileNavMenu.scss';

const sectionMessages = defineMessages({
    messages: {
        id: 'mobile.navigation.messages',
        defaultMessage: 'Inbox',
    },
    orders: {
        id: 'mobile.navigation.orders',
        defaultMessage: 'Orders',
    },
    accountInformation: {
        id: 'mobile.navigation.accountInformation',
        defaultMessage: 'Account Information',
    },
    activeOffer: {
        id: 'mobile.navigation.activeOffer',
        defaultMessage: 'Active Offers',
    },
    topItem: {
        id: 'mobile.navigation.topItem',
        defaultMessage: 'See all {name}',
    },
    categories: {
        id: 'mobile.navigation.categories',
        defaultMessage: 'Categories',
    },
    account: {
        id: 'mobile.navigation.account',
        defaultMessage: 'Account',
    },
    nft: {
        id: 'mobile.navigation.nftCollector',
        defaultMessage: 'NFT History',
    },
    nftProfile: {
        id: 'mobile.navigation.nftProfile',
        defaultMessage: 'NFT Profile',
    },
});

const getNavigationSections = intl => ({
    messagesSection: {
        name: intl.formatMessage(sectionMessages.messages),
        type: LINK_ELEMENT_TYPE_ANCHOR,
        linkData: {
            path: '/inbox/',
        },
    },

    ordersSection: {
        name: intl.formatMessage(sectionMessages.orders),
        type: LINK_ELEMENT_TYPE_ANCHOR,
        linkData: {
            path: '/orders/',
        },
    },

    nftSection: {
        name: intl.formatMessage(sectionMessages.nft),
        type: LINK_ELEMENT_TYPE_ANCHOR,
        linkData: {
            path: '/nft/profile/',
        },
    },

    accountSection: {
        name: intl.formatMessage(sectionMessages.accountInformation),
        type: LINK_ELEMENT_TYPE_ANCHOR,
        linkData: {
            path: '/my/account/',
        },
    },

    activeOfferSection: {
        name: intl.formatMessage(sectionMessages.activeOffer),
        type: LINK_ELEMENT_TYPE_ANCHOR,
        linkData: {
            path: '/inbox/?filter=activePrivateOffer',
        },
    },
});

function getModuleIdentifier(module) {
    const name = module.name || '';
    return name.toLowerCase();
}

function findModuleByIdentifier(modules, identifier) {
    return modules.find(module => getModuleIdentifier(module) === identifier);
}

class MobileNavMenu extends Component {
    constructor() {
        super();

        this.onMainMenuItemClick = this.onMainMenuItemClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
        this.onDrilldownTransitionEnd = this.onDrilldownTransitionEnd.bind(this);

        this.state = {
            activeDrilldown: null,
            isDrilldownOpen: false,
            sectionToShow: '',
        };
    }

    componentDidMount() {
        this.setState({
            isEmailOnlyUser: isEmailOnly(document.cookie),
        });
    }

    onMainMenuItemClick(menuItem, e) {
        const module = findModuleByIdentifier(this.getNavModules(), getModuleIdentifier(menuItem));

        /**
         * If there's no module found, we assume that the drilldown shouldn't be actived and we just fire the click
         * handler.
         */
        if (module && module.items && module.items.length) {
            // prevent default anchor click navigation if module found
            e?.preventDefault();
            const sectionToShow = module.name;

            this.setState({
                isDrilldownOpen: true,
                sectionToShow,
            });
        }
    }

    getNavModules() {
        const { viewer } = this.props;
        return viewer?.navigation?.modules || [];
    }

    onDrilldownTransitionEnd() {
        const { isDrilldownOpen } = this.state;
        this.props.onTransitionEnd(isDrilldownOpen);
        if (!isDrilldownOpen) {
            this.setState({
                sectionToShow: '',
            });
        }
    }

    onBackClick() {
        this.setState({
            isDrilldownOpen: false,
        });
    }

    render() {
        const { isDrilldownOpen, sectionToShow, isEmailOnlyUser } = this.state;

        const { onAuthClick, user, isFullWidth, viewer } = this.props;
        const { messagesSection, ordersSection, accountSection, nftSection, activeOfferSection } =
            getNavigationSections(this.context);

        const accountSections = !isEmailOnlyUser
            ? [ordersSection, accountSection]
            : [accountSection];

        const hasEthereumWallet = !!user?.ethereumWalletAddress?.address;
        if (hasEthereumWallet) {
            nftSection.name = this.context.formatMessage(sectionMessages.nftProfile);
            accountSections.splice(accountSections.length - 1, 0, nftSection);
        }

        const activeOffers = user?.conversationSummary?.unreadActiveOffers || 0;
        if (viewer.activeOfferNavFF && !isEmailOnlyUser) {
            accountSections.unshift(
                Object.assign({}, activeOfferSection, {
                    count: activeOffers,
                })
            );
        }

        if (!isEmailOnlyUser) {
            accountSections.unshift(
                Object.assign({}, messagesSection, {
                    count: user?.conversationSummary?.totalUnreadConversationCount || 0,
                })
            );
        }

        const categoriesSections = this.getNavModules();

        const mobileNavMenuBodySectionsInfo = {
            title: this.context.formatMessage(sectionMessages.categories),
            additionalSections: [
                {
                    title: this.context.formatMessage(sectionMessages.account),
                    items: user ? accountSections : [],
                },
            ],
        };

        const bodyContainerClasses = classnames(styles.bodyContainer, {
            [styles.isFullWidth]: isFullWidth,
        });

        return (
            <div className={bodyContainerClasses}>
                <MobileNavAnimationSlide show={!isDrilldownOpen}>
                    <MobileNavMenuBody
                        user={user}
                        viewer={viewer}
                        onAuthClick={onAuthClick}
                        className={styles.bodyItem}
                        onItemClick={this.onMainMenuItemClick}
                        sectionsInfo={mobileNavMenuBodySectionsInfo}
                        modules={categoriesSections}
                    />
                </MobileNavAnimationSlide>
                <MobileNavAnimationSlide
                    onTransitionEnd={this.onDrilldownTransitionEnd}
                    show={isDrilldownOpen}
                    leave="right"
                    appear="right"
                >
                    {categoriesSections.map(module => {
                        return (
                            <MobileNavDrilldown
                                key={module.name}
                                onBackClick={this.onBackClick}
                                parentSectionName={module.name}
                                sectionToShow={sectionToShow}
                                module={module}
                            />
                        );
                    })}
                </MobileNavAnimationSlide>
            </div>
        );
    }
}

MobileNavMenu.contextType = IntlContext;

MobileNavMenu.defaultProps = {
    isFullWidth: false,
    isAppBannerVisible: false,
};

MobileNavMenu.propTypes = {
    onAuthClick: func.isRequired,
    viewer: object.isRequired,
    onTransitionEnd: func.isRequired,
    user: object,
    isFullWidth: bool,
    isAppBannerVisible: bool,
};

export default createFragmentContainer(MobileNavMenu, {
    viewer: graphql`
        fragment MobileNavMenu_viewer on Viewer {
            activeOfferNavFF: featureFlag(feature: "activeOfferNav")
            ...MobileNavMenuBody_viewer
            navigation(rootId: $previewKey, variantId: $variantId) {
                modules {
                    name
                    items {
                        serviceId
                        items {
                            serviceId
                        }
                    }
                    ...MobileNavDrilldown_module
                    ...MobileNavMenuBody_modules
                }
            }
        }
    `,
    user: graphql`
        fragment MobileNavMenu_user on User {
            conversationSummary {
                totalUnreadConversationCount
                unreadActiveOffers
            }
            ethereumWalletAddress {
                address
            }
            ...MobileNavMenuBody_user
        }
    `,
});
