/**
 * @generated SignedSource<<d213185f0fedef54ff853bfa4b538e0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNav_regionalInfo$data = ReadonlyArray<{
  readonly currency: string | null;
  readonly " $fragmentType": "MobileNav_regionalInfo";
}>;
export type MobileNav_regionalInfo$key = ReadonlyArray<{
  readonly " $data"?: MobileNav_regionalInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNav_regionalInfo">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MobileNav_regionalInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "RegionalInfo",
  "abstractKey": null
};

(node as any).hash = "078943ea20f399e1f74dc2e289acfa83";

export default node;
