import { type FC } from 'react';
import classNames from 'classnames';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import FavoritesIcon from 'dibs-icons/exports/legacy/HeartOutlined';
import styles from './mobileNavIcons.scss';

const URL_PATH_FAVORITES = '/favorites/items/';

type Props = {
    className: string;
};

const MobileNavFavorite: FC<Props> = ({ className }) => {
    const iconClassName = classNames(styles.icon, styles.favorite);

    return (
        <SeoLink
            className={className}
            linkData={{ path: URL_PATH_FAVORITES }}
            dataTn="favorite-icon"
        >
            <FavoritesIcon className={iconClassName} />
        </SeoLink>
    );
};

export default MobileNavFavorite;
