import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type HamburgerMenuProps = {
    className?: string;
};

const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'hamburger-menu'}
            viewBox="0 0 250 250"
        >
            <rect x="0.6" y="61.2" width="249" height="9" />
            <rect x="0.6" y="120" width="249" height="9" />
            <rect x="0.6" y="179.4" width="249" height="9" />
        </svg>
    );
};
export default HamburgerMenu;
