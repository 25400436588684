/**
 * @generated SignedSource<<ffeca2617f67944d3d5835d520b2990b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavHeader_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavCartContainer_viewer">;
  readonly " $fragmentType": "MobileNavHeader_viewer";
};
export type MobileNavHeader_viewer$key = {
  readonly " $data"?: MobileNavHeader_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavHeader_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNavHeader_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavCartContainer_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "81e2f3621928de2d75fdf5521cbe6210";

export default node;
