/**
 * @generated SignedSource<<50403a13c03b366d2b264c078f8e7d03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavMenuBody_modules$data = ReadonlyArray<{
  readonly linkData: {
    readonly path: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SeoLink_linkData">;
  };
  readonly name: string | null;
  readonly " $fragmentType": "MobileNavMenuBody_modules";
}>;
export type MobileNavMenuBody_modules$key = ReadonlyArray<{
  readonly " $data"?: MobileNavMenuBody_modules$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavMenuBody_modules">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MobileNavMenuBody_modules",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "linkData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeoLink_linkData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NavigationModule",
  "abstractKey": null
};

(node as any).hash = "5c9455b9031f4d2a433b340ae55ed0af";

export default node;
