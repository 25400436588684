import { number, string } from 'prop-types';

export default function MobileNavDisplayAmount(props) {
    const { max = 99, className = '' } = props;
    const isMax = props.amount > max;
    const amount = isMax ? max : props.amount;
    return (
        <div className={className}>
            {amount}
            {isMax && <sup>+</sup>}
        </div>
    );
}

MobileNavDisplayAmount.propTypes = {
    max: number,
    className: string,
    amount: number.isRequired,
};
