import { bool, string, element, oneOfType, arrayOf, func } from 'prop-types';
import { connect } from 'react-redux';
import styles from './mobileNavAnimations.scss';
import classnames from 'classnames';

export const MobileNavAnimationSlideComponent = props => {
    const {
        appear = 'left',
        leave = 'left',
        children,
        show = false,
        onTransitionEnd,
        topLevel = false,
        isFullWidth = false,
        isAppBannerVisible = false,
        shouldRepaint,
    } = props;

    const enterClass = styles[`${appear}ActiveEnter`];
    const exitClass = styles[`${leave}ActiveExit`];

    const topLevelWrapper = classnames(styles.topLevelWrapper, {
        [styles.isTransparent]: !isFullWidth,
        [styles.isBelowAppBanner]: isAppBannerVisible,
        /**
         * Use repaint flag for triggering repaint on top level wrapper.
         * In safari there is a bug with `position:fixed` elements causing strange scroll behaviour on elements
         * More information: https://bugs.webkit.org/show_bug.cgi?id=160953
         **/
        [styles.repaint]: shouldRepaint,
    });

    const wrapperClassName = classnames(styles.container, {
        [topLevelWrapper]: topLevel,
        [enterClass]: show,
        [exitClass]: !show,
    });

    return (
        <div onTransitionEnd={onTransitionEnd} className={wrapperClassName}>
            {children}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        isAppBannerVisible: state.appBanner.isAppBannerVisible,
    };
}

MobileNavAnimationSlideComponent.propTypes = {
    show: bool,
    appear: string,
    leave: string,
    children: oneOfType([element, arrayOf(element)]),
    topLevel: bool,
    onTransitionEnd: func,
    isFullWidth: bool,
    // redux
    isAppBannerVisible: bool,
    shouldRepaint: bool,
};

export const MobileNavAnimationSlide = connect(mapStateToProps)(MobileNavAnimationSlideComponent);
