import { Component } from 'react';
import { array, func, object, string, number } from 'prop-types';
import { connect } from 'react-redux';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { localStorage } from 'dibs-browser-storage';
import {
    loadLocalCart,
    removeLocalCart as removeLocalCartAction,
} from '../../../actions/cartActions';
import MobileNavCart from './MobileNavCart';
import { addCartItemMutation } from '../../CartNav/addCartItemMutation';
import { addMultiCartItems } from '../../CartNav/addMultiCartItemsMutation';
import { actionCreators } from '../../../actions';
import { wasPortfolioItemRemoved } from '../../../helpers/cartHelpers';

class MobileNavCartContainer extends Component {
    componentDidMount() {
        const isMultiItemCartFF = this.props.viewer?.isMultiItemCartFF;
        this.props.loadLocalCart(isMultiItemCartFF);
    }

    componentDidUpdate(prevProps) {
        const {
            localCartItems,
            relay,
            user,
            removedPortfolioCartItemId,
            removeLocalCart,
            setAddCartItemMutationCompleted,
            cartRefetchTrigger,
        } = this.props;
        const {
            removedPortfolioCartItemId: prevRemovedCartItemId,
            cartRefetchTrigger: prevCartRefetchTrigger,
        } = prevProps;

        if (
            user &&
            (wasPortfolioItemRemoved({ prevRemovedCartItemId, removedPortfolioCartItemId }) ||
                cartRefetchTrigger !== prevCartRefetchTrigger)
        ) {
            this.props.relay.refetch();
        }
        if (user && localCartItems.length) {
            if (this.props.viewer?.isMultiItemCartFF) {
                addMultiCartItems({
                    items: localCartItems,
                    onCompleted: setAddCartItemMutationCompleted,
                    removeLocalCart,
                    environment: relay.environment,
                    onFailedItems: failedItemIds => {
                        localStorage.setItem('failedCartSyncItems', failedItemIds);
                    },
                });
            } else {
                addCartItemMutation({
                    environment: relay.environment,
                    itemsIds: localCartItems.map(({ id }) => id),
                    userId: user.serviceId,
                    onCompleted: () => {
                        removeLocalCart();
                        setAddCartItemMutationCompleted();
                    },
                    onError: error => {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    },
                });
            }
        }
    }

    render() {
        const { localCartItems, viewer } = this.props;
        const totalResults = viewer?.portfolios?.edges?.[0]?.node?.items?.totalResults;
        const localItemsCount = viewer?.isMultiItemCartFF
            ? localCartItems?.reduce((totalCount, localItem) => {
                  if (localItem?.type === 'CHECKOUT') {
                      totalCount += localItem.quantity;
                  }
                  return totalCount;
              }, 0)
            : localCartItems.length;
        const cartItemCount = localItemsCount || totalResults;

        return <MobileNavCart cartItemCount={cartItemCount} />;
    }
}

MobileNavCartContainer.propTypes = {
    localCartItems: array.isRequired,
    relay: object.isRequired,
    removeLocalCart: func.isRequired,
    setAddCartItemMutationCompleted: func.isRequired,
    loadLocalCart: func.isRequired,
    user: object,
    viewer: object,
    removedPortfolioCartItemId: string,
    cartRefetchTrigger: number,
};

const mapStateToProps = ({ cart }) => {
    const localCartItems = cart?.items || [];
    const removedPortfolioCartItemId = cart?.removedPortfolioCartItemId;
    return {
        localCartItems,
        removedPortfolioCartItemId,
        cartRefetchTrigger: cart?.cartRefetchTrigger,
    };
};

const mapDispatchToProps = dispatch => ({
    removeLocalCart: () => dispatch(removeLocalCartAction()),
    setAddCartItemMutationCompleted: () =>
        dispatch(actionCreators.setAddCartItemMutationCompleted()),
    loadLocalCart: isMultiItemCartFF => dispatch(loadLocalCart(isMultiItemCartFF)),
});

MobileNavCartContainer = connect(mapStateToProps, mapDispatchToProps)(MobileNavCartContainer);

export default createRefetchContainer(
    MobileNavCartContainer,
    {
        viewer: graphql`
            fragment MobileNavCartContainer_viewer on Viewer {
                isMultiItemCartFF: featureFlag(feature: "multiItemCart")
                portfolios(userIds: $userIds, portfolioTypes: [CART], first: 1)
                    @include(if: $hasUserId) {
                    edges {
                        node {
                            items {
                                totalResults
                            }
                        }
                    }
                }
            }
        `,
        user: graphql`
            fragment MobileNavCartContainer_user on User {
                serviceId
            }
        `,
    },
    graphql`
        query MobileNavCartContainerRefetchQuery($hasUserId: Boolean!, $userIds: [String!]) {
            viewer {
                ...MobileNavCartContainer_viewer
            }
        }
    `
);
