import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import MobileNavWrapper from '../MobileNavWrapper/MobileNavWrapper';
import { PrepareRelayContextProvider } from '../PrepareRelayContextProvider/PrepareRelayContextProvider';

function MobileNavRenderer(props = {}) {
    const { environment, initialVariables, query, relayVariables } = props;

    const variables = {
        ...initialVariables,
        ...relayVariables,
    };

    return (
        <PrepareRelayContextProvider
            environment={environment}
            query={query}
            variables={variables}
            render={({ props: rendererProps }) => (
                <MobileNavWrapper {...rendererProps} {...variables} />
            )}
        />
    );
}

MobileNavRenderer.propTypes = {
    environment: object,
    initialVariables: object,
    query: object,
    relayVariables: object,
    messageBannerRenderer: func,
};

const mapStateToProps = ({ header }) => {
    return {
        relayVariables: header.relayVariables || {},
    };
};

export default connect(mapStateToProps)(MobileNavRenderer);
