const oneLinkUrl = 'https://1stdibs.onelink.me/w9Bb/';
const ORDER_CONFIRMATION_RESP = `${oneLinkUrl}77d3f803` as const;
const ORDER_CONFIRMATION_MOBILE = `${oneLinkUrl}q2c37jas` as const;
const CONTACT_SELLER_CONFIRMATION = `${oneLinkUrl}3da3a1b5` as const;
const FOOTER_NAV_LINK = `${oneLinkUrl}cdd071e9` as const;
const MW_HOMEPAGE_BANNER = `${oneLinkUrl}32b5488d` as const;
const IPAD_HOMEPAGE_BANNER = `${oneLinkUrl}164591d` as const;
const MW_APP_DOWNLOAD_BANNER = `${oneLinkUrl}7b27c8d1` as const;
const MW_APP_DOWNLOAD_PILL_FAVORITES_ITEMS = `${oneLinkUrl}c5f272a1  ` as const;
const MW_APP_DOWNLOAD_PILL_FAVORITES_FOLDERS = `${oneLinkUrl}61edbd36 ` as const;
const MW_APP_DOWNLOAD_PILL_FAVORITES_SAVES = `${oneLinkUrl}b54231fc ` as const;
const APP_DOWNLOAD_MODAL = `${oneLinkUrl}6ohniekf` as const;
const MW_APP_DOWNLOAD_MODAL = `${oneLinkUrl}zuqzvpfs` as const;

const appLinks = {
    ORDER_CONFIRMATION_RESP,
    ORDER_CONFIRMATION_MOBILE,
    CONTACT_SELLER_CONFIRMATION,
    FOOTER_NAV_LINK,
    MW_HOMEPAGE_BANNER,
    IPAD_HOMEPAGE_BANNER,
    MW_APP_DOWNLOAD_BANNER,
    MW_APP_DOWNLOAD_PILL_FAVORITES_ITEMS,
    MW_APP_DOWNLOAD_PILL_FAVORITES_FOLDERS,
    MW_APP_DOWNLOAD_PILL_FAVORITES_SAVES,
    APP_DOWNLOAD_MODAL,
    MW_APP_DOWNLOAD_MODAL,
};

export default appLinks;
