import { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';

import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const {
    STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_CLICK,
    STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_FOCUS_CLICK,
} = stepInteractionConstants;
import { SearchBar } from 'dibs-elements/exports/SearchBar';
import { Link } from 'dibs-elements/exports/Link';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';
import { getLocalHistoryItemIds } from 'dibs-recent-history/exports/getLocalHistoryItemIds';
import { MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS } from 'dibs-recent-history/exports/constants';

import { SearchBarOverlay } from '../../SearchBar/SearchBarOverlay';
import ScrollLock from '../ScrollLock';
import { useSearchSuggestions } from '../../../hooks/useSearchSuggestions';
import { useSearchBarTracking } from '../../../hooks/useSearchBarTracking';
import { handleRecentStorage } from '../../../utils/sharedSearchBarHelpers';
import {
    SEARCH_PAGE_PATH,
    SEARCH_QUERY_PARAM,
    SEARCH_BAR_TRACKING,
} from '../../../utils/sharedSearchBarConstants';
import { isSellerBrandingRemovalTestVariant } from '../../../utils/abTest/sellerBrandingRemovalAbTestHelpers';

import styles from './mobileNavSearchBar.scss';

const SEARCH_BAR_EXPANDED = 'expanded';
const SEARCH_BAR_NONE = 'none';
const MIN_NUM_VALUE_CHARACTERS = 2;
export const SEARCH_BAR_ID = 'nav-search';

const messages = defineMessages({
    placeholder: {
        id: 'dbl.Header.MobileNav.placeholder',
        defaultMessage: 'Search',
    },
    closeSearchLabel: {
        id: 'dbl.Header.MobileNav.closeSearchLabel',
        defaultMessage: 'Exit search',
    },
});

export const MobileNavSearchBarComponent = props => {
    const intl = useIntl();

    const [animationType, setAnimationType] = useState(SEARCH_BAR_NONE);
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isSearchBarCleared, setIsSearchBarCleared] = useState(false);
    const [cursorTerms, setCursorTerms] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(-1);
    const overlayWrapper = useRef(null);

    const {
        isAnimatedSearchBar,
        isBelowAppBanner,
        isClient,
        showArrow,
        focusOnOverlayScroll,
        showScrollLockedOverlay,
        relay,
        viewer,
        onlySearchBarIsVisible,
        setOnlySearchBarIsVisible,
    } = props;
    const { user, isMobileRecentSearchUiDisabled } = viewer || {};

    const { suggestions, recentSearches, trackEventLabel } = useSearchSuggestions({ viewer });

    const isSearchBarExpanded = animationType === SEARCH_BAR_EXPANDED;

    const onFocus = useCallback(
        event => {
            trackEvent(
                {
                    category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                    action: SEARCH_BAR_TRACKING.ACTIONS.SEARCH_BAR_CLICK,
                    eventName: EVENT_NAVIGATION,
                    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                    step_interaction_name:
                        STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_FOCUS_CLICK,
                },
                event
            );
            setIsFocused(true);
            setOnlySearchBarIsVisible(true);
            if (isAnimatedSearchBar && animationType === SEARCH_BAR_NONE) {
                setAnimationType(SEARCH_BAR_EXPANDED);
            }
        },
        [
            setIsFocused,
            isAnimatedSearchBar,
            animationType,
            setAnimationType,
            setOnlySearchBarIsVisible,
        ]
    );

    const onBlur = useCallback(
        e => {
            const { value: val } = e.target;
            if (value !== val) {
                setValue(val);
            }
        },
        [value]
    );

    const setSearchTermFromParams = useCallback(() => {
        if (location.href.indexOf(SEARCH_PAGE_PATH) !== -1) {
            const searchParams = new URLSearchParams(location.search);
            const searchTerm = searchParams.get(SEARCH_QUERY_PARAM);

            if (searchTerm) {
                setValue(searchTerm);
            }
        }
    }, [setValue]);

    const handleRowClick = useCallback(
        (val, href, event) => {
            setValue(val);
            setIsFocused(false);
            setOnlySearchBarIsVisible(false);

            trackEvent(
                {
                    category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                    action: SEARCH_BAR_TRACKING.ACTIONS.HEADER_NAV_CLICK,
                    label: trackEventLabel,
                    eventName: EVENT_NAVIGATION,
                    interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                    step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_CLICK,
                    trigger: trackEventLabel,
                },
                event
            );
            handleRecentStorage(val, href);
            location.assign(handleLocaleUrl(href, GLOBAL_CLIENT_ONLY_LOCALE));
        },
        [trackEventLabel, setValue, setIsFocused, setOnlySearchBarIsVisible]
    );

    const fetchSuggestions = useCallback(() => {
        const localRecentHistoryItemIds = getLocalHistoryItemIds({ isClient: true });
        const fragmentVariables = {
            hasQuery: value.length > 1,
            query: value.toLowerCase(),
            localRecentHistoryItemIds,
            fetchUserRecentHistoryItem:
                localRecentHistoryItemIds.length < MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
            includeTypes: isSellerBrandingRemovalTestVariant()
                ? ['POPULAR', 'CATEGORY', 'NO_SELLER', 'CREATOR']
                : ['POPULAR', 'CATEGORY', 'SELLER', 'CREATOR'],
        };
        relay.refetch(
            () => fragmentVariables,
            { ...fragmentVariables, fetchUserRecentHistoryItem: false },
            () => setIsSearchBarCleared(false)
        );
    }, [value, relay]);

    const [debouncedFetchSuggestions] = useDebouncedCallback(fetchSuggestions, 250);

    const handleChange = useCallback(
        newValue => {
            if (!newValue) {
                setIsSearchBarCleared(true);
            }
            setValue(newValue);
            setCursorTerms([]);
            setCursorPosition(-1);
        },
        [setValue, setCursorTerms, setCursorPosition]
    );

    const handleClear = useCallback(() => {
        if (isAnimatedSearchBar) {
            setIsFocused(false);
            setAnimationType(SEARCH_BAR_NONE);
        }
    }, [isAnimatedSearchBar, setIsFocused, setAnimationType]);

    const handleSubmit = useCallback(
        event => {
            event.preventDefault();
            let urlLabel;

            if (cursorPosition > -1 && cursorTerms[cursorPosition].link) {
                handleRowClick(
                    cursorTerms[cursorPosition].term,
                    cursorTerms[cursorPosition].link,
                    event
                );
            } else if (value) {
                urlLabel = `/search/?q=${encodeURIComponent(value.toLowerCase())}`;
                trackEvent(
                    {
                        category: SEARCH_BAR_TRACKING.CATEGORIES.NAVIGATION,
                        action: SEARCH_BAR_TRACKING.ACTIONS.HEADER_NAV_CLICK,
                        label: SEARCH_BAR_TRACKING.LABELS.GLOBAL_SEARCH,
                        eventName: EVENT_NAVIGATION,
                        interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
                        step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_MOBILE_SEARCH_BAR_CLICK,
                        trigger: SEARCH_BAR_TRACKING.LABELS.GLOBAL_SEARCH,
                    },
                    event
                );
                handleRecentStorage(value, urlLabel);
                location.assign(handleLocaleUrl(urlLabel, GLOBAL_CLIENT_ONLY_LOCALE));
            }
        },
        [value, cursorTerms, cursorPosition, handleRowClick]
    );
    const handleKeydown = useCallback(
        e => {
            switch (e.key) {
                case 'Esc':
                case 'Escape':
                    // close overlay on [Esc]
                    setIsFocused(false);
                    setOnlySearchBarIsVisible(false);
                    break;

                default:
                    break;
            }
        },
        [setIsFocused, setOnlySearchBarIsVisible]
    );
    useEffect(() => {
        if (showArrow && onFocus && isFocused) {
            document.addEventListener('keydown', handleKeydown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [showArrow, onFocus, isFocused, handleKeydown]);

    useEffect(() => {
        if (value) {
            debouncedFetchSuggestions(value);
        }
    }, [value, debouncedFetchSuggestions]);

    useEffect(() => {
        setSearchTermFromParams();
    }, [setSearchTermFromParams]);

    const searchBarWrapper = useRef(null);

    useEffect(() => {
        function handleOutsideFocus(e) {
            if (isFocused && !searchBarWrapper?.current?.contains(e.target)) {
                setIsFocused(false);
                setOnlySearchBarIsVisible(false);
            }
        }

        if (isFocused) {
            document.body.addEventListener('focus', handleOutsideFocus, true);
        }
        return () => {
            document.body.removeEventListener('focus', handleOutsideFocus, true);
        };
    }, [isFocused, searchBarWrapper, setOnlySearchBarIsVisible]);

    const showRecentlyViewed =
        !suggestions || value.length < MIN_NUM_VALUE_CHARACTERS || isSearchBarCleared;

    let showOverlay;
    if (isAnimatedSearchBar || !isMobileRecentSearchUiDisabled) {
        showOverlay = isFocused;
    } else if (isMobileRecentSearchUiDisabled) {
        showOverlay = isFocused && !showRecentlyViewed;
    }

    useSearchBarTracking({
        isVisible: showOverlay,
        showRecentlyViewed,
        recentSearches,
        suggestions,
    });

    const wrapperClasses = classnames(styles.wrapper, {
        [styles.animatedSearchBar]: isAnimatedSearchBar,
        [styles.expanded]: isSearchBarExpanded,
        [styles.belowAppBanner]: isBelowAppBanner,
        [styles.onlySearchBarIsVisible]: onlySearchBarIsVisible,
    });
    const overlayWrapperClasses = classnames({
        [styles.overlayWrapper]: showOverlay,
        [styles.belowAppBanner]: isBelowAppBanner,
    });
    const overlayClasses = classnames(styles.suggestionsOverlay, {
        [styles.belowAppBanner]: isBelowAppBanner,
    });
    const whiteOverlayClasses = classnames(styles.whiteOverlay, {
        [styles.visible]: isFocused,
        [styles.belowAppBanner]: isBelowAppBanner,
    });

    const isClearButtonHidden = isAnimatedSearchBar ? !isFocused : !value;

    return (
        <div id={SEARCH_BAR_ID} className={wrapperClasses} ref={searchBarWrapper}>
            <form role="search" action="/search/" onSubmit={handleSubmit}>
                <div className={styles.searchBar}>
                    {showArrow && onFocus && (
                        <Link
                            className={styles.arrow}
                            onClick={() => setIsFocused(false)}
                            ariaLabel={intl.formatMessage(messages.closeSearchLabel)}
                        >
                            <ArrowLeft />
                        </Link>
                    )}
                    <SearchBar
                        dataTn="search-bar-mobileWeb"
                        hasRightSearchButton={false}
                        focusOnClear={!isAnimatedSearchBar}
                        noBorder={onlySearchBarIsVisible}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={handleChange}
                        onClear={handleClear}
                        placeholder={intl.formatMessage(messages.placeholder)}
                        size="medium"
                        value={value}
                        isClearButtonHidden={isClearButtonHidden}
                        isHeaderSearch
                        dropdownIsOpen={isFocused}
                    />
                    {onlySearchBarIsVisible && (
                        <Link
                            className={styles.cancelButton}
                            onClick={() => {
                                setIsFocused(false);
                                setOnlySearchBarIsVisible(false);
                            }}
                            ariaLabel={intl.formatMessage(messages.closeSearchLabel)}
                        >
                            <FormattedMessage
                                id="dbl.Header.MobileNav.cancel"
                                defaultMessage="Cancel"
                            />
                        </Link>
                    )}
                </div>
            </form>
            {showScrollLockedOverlay && (
                <ScrollLock isLocked={showOverlay}>
                    <div className={whiteOverlayClasses} />
                </ScrollLock>
            )}
            <div className={overlayWrapperClasses} ref={overlayWrapper}>
                {isClient ? (
                    <SearchBarOverlay
                        overlayClassName={overlayClasses}
                        canShowRecentlyViewed={isFocused && showRecentlyViewed}
                        handleRowClick={handleRowClick}
                        focusOnOverlayScroll={focusOnOverlayScroll}
                        visible={showOverlay}
                        suggestions={showRecentlyViewed ? recentSearches : suggestions}
                        searchTerm={value}
                        user={user || null}
                    />
                ) : null}
            </div>
        </div>
    );
};

MobileNavSearchBarComponent.propTypes = {
    focusOnOverlayScroll: PropTypes.bool,
    isAnimatedSearchBar: PropTypes.bool,
    isClient: PropTypes.bool,
    isBelowAppBanner: PropTypes.bool,
    showArrow: PropTypes.bool,
    showScrollLockedOverlay: PropTypes.bool,
    relay: PropTypes.object,
    viewer: PropTypes.object,
    onlySearchBarIsVisible: PropTypes.bool,
    setOnlySearchBarIsVisible: PropTypes.func,
};

export const MobileNavSearchBar = createRefetchContainer(
    MobileNavSearchBarComponent,
    {
        viewer: graphql`
            fragment MobileNavSearchBar_viewer on Viewer
            @argumentDefinitions(
                query: { type: "String", defaultValue: "" }
                hasQuery: { type: "Boolean", defaultValue: false }
            ) {
                isMobileRecentSearchUiDisabled: isEnabled(key: "disableMobileSearchRecentUi")
                ...useSearchSuggestions_viewer @arguments(query: $query, hasQuery: $hasQuery)
                user(userId: $userId) @include(if: $hasUserId) {
                    ...getRecentHistory_user @defer
                }
            }
        `,
    },
    graphql`
        query MobileNavSearchBarRefetchQuery(
            $query: String!
            $hasQuery: Boolean!
            $userId: String!
            $hasUserId: Boolean!
            $localRecentHistoryItemIds: [String] = []
            $includeTypes: [String] = []
            $fetchUserRecentHistoryItem: Boolean = false
        ) {
            viewer {
                ...MobileNavSearchBar_viewer @arguments(query: $query, hasQuery: $hasQuery)
            }
        }
    `
);
