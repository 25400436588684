import { Component } from 'react';
import { string, bool, oneOfType, node, element } from 'prop-types';
import classnames from 'classnames';
import styles from './MobileNavDrawer.scss';
import throttle from 'lodash.throttle';

const DURATION_SCROLL_DATA_RESET = 250;
const DURATION_THROTTLE_SCROLL_EVENTS = 100;

/**
 * Amount of pixels you need to scroll to trigger an open or close animation.
 */
const PIXELS_SCROLL_DISTANCE = 50;

/**
 * The amount of pixels from the top of the page, where the drawer should always be open.
 */
const PIXELS_FORCE_APPEAR = 100;

/* eslint-disable import/no-default-export */

export default class MobileNavDrawer extends Component {
    constructor() {
        super();

        this.state = {
            isOpen: true,
        };

        this.scroll = {
            last: 0,
            distance: 0,
        };
        this.resetTimeout = null;
        this.scrollTimeout = null;
        this.isAnimating = false;

        this.onScroll = throttle(this.onScroll.bind(this), DURATION_THROTTLE_SCROLL_EVENTS);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    }

    getScrollY() {
        return Math.round(window.pageYOffset);
    }

    resetScrollData() {
        this.scroll = {
            last: 0,
            distance: 0,
        };
    }

    onTransitionEnd() {
        if (this.isAnimating) {
            this.isAnimating = false;
        }
    }

    getNextDrawerState(currentScrollPosition) {
        const { isOpen } = this.state;
        // Number of pixels scrolled.
        const scrollDelta = this.scroll.last - currentScrollPosition;
        // Total distance scrolled since the first scroll event has been captured.
        const scrollDistance = (this.scroll.distance += scrollDelta);
        const forceOpen = currentScrollPosition <= PIXELS_FORCE_APPEAR;
        const shouldClose = !forceOpen && scrollDistance < -PIXELS_SCROLL_DISTANCE;
        const shouldOpen = scrollDistance > PIXELS_SCROLL_DISTANCE || forceOpen;

        if (!isOpen && shouldOpen) {
            return true;
        } else if (isOpen && shouldClose && !shouldOpen) {
            return false;
        }

        return isOpen;
    }

    onScroll() {
        if (this.props.disabled) {
            return;
        }

        /**
         * If the drawer is animating, we really need to keep JS work to a minimum,
         * so that the animation can run smoothly.
         *
         * Since this discards some scroll events, we set a timeout to check wheter everything
         * is correct.
         */
        clearTimeout(this.scrollTimeout);
        if (this.isAnimating) {
            this.scrollTimeout = setTimeout(() => this.onScroll(), DURATION_SCROLL_DATA_RESET);
            return;
        }
        /**
         * Once we know the animation isn't running - figure out if we need to hide/show the
         * drawer for this particular scroll event.
         */
        const currentScrollPosition = this.getScrollY();
        const scrollData = this.scroll;

        /**
         * 0 means it's the first scroll after the data has been reset. That means the
         * scrolling distance should also be 0. Setting last to currentScrollPosition
         * achieve just that.
         */
        if (scrollData.last === 0) {
            scrollData.last = currentScrollPosition;
        }

        /**
         * Figure out if the drawer state needs to change.
         */
        const nextDrawerState = this.getNextDrawerState(currentScrollPosition);
        if (nextDrawerState !== this.state.isOpen) {
            this.setState({
                isOpen: nextDrawerState,
            });
            this.isAnimating = true;
        }

        /**
         * Record the scrolling position, so we can accuratly compute the distance on the
         * next scroll event.
         */
        scrollData.last = currentScrollPosition;

        /**
         * We mimick a scroll start event by reseting the scroll state, if a scroll event
         * hasn't been captured for a specified amount of time.
         */
        clearTimeout(this.resetTimeout);
        this.resetTimeout = setTimeout(() => this.resetScrollData(), DURATION_SCROLL_DATA_RESET);
    }

    render() {
        const { isOpen } = this.state;
        const classes = classnames(this.props.className, styles.drawer, {
            [styles.isClosed]: !isOpen,
        });

        return (
            <div className={classes} onTransitionEnd={this.onTransitionEnd}>
                {this.props.children}
            </div>
        );
    }
}

MobileNavDrawer.propTypes = {
    className: string,
    disabled: bool,
    children: oneOfType([element, node]),
};
