/**
 * @generated SignedSource<<99fd85ecb8a1da0a427647a296ca7d57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavMenu_viewer$data = {
  readonly activeOfferNavFF: boolean | null;
  readonly navigation: {
    readonly modules: ReadonlyArray<{
      readonly items: ReadonlyArray<{
        readonly items: ReadonlyArray<{
          readonly serviceId: string | null;
        } | null> | null;
        readonly serviceId: string | null;
      } | null> | null;
      readonly name: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"MobileNavDrilldown_module" | "MobileNavMenuBody_modules">;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavMenuBody_viewer">;
  readonly " $fragmentType": "MobileNavMenu_viewer";
};
export type MobileNavMenu_viewer$key = {
  readonly " $data"?: MobileNavMenu_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavMenu_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "previewKey"
    },
    {
      "kind": "RootArgument",
      "name": "variantId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNavMenu_viewer",
  "selections": [
    {
      "alias": "activeOfferNavFF",
      "args": [
        {
          "kind": "Literal",
          "name": "feature",
          "value": "activeOfferNav"
        }
      ],
      "kind": "ScalarField",
      "name": "featureFlag",
      "storageKey": "featureFlag(feature:\"activeOfferNav\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavMenuBody_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "rootId",
          "variableName": "previewKey"
        },
        {
          "kind": "Variable",
          "name": "variantId",
          "variableName": "variantId"
        }
      ],
      "concreteType": "Navigation",
      "kind": "LinkedField",
      "name": "navigation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NavigationModule",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "NavigationTopLevel",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NavigationItem",
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MobileNavDrilldown_module"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MobileNavMenuBody_modules"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "a6f2432933c12aab020f3871fc8c55ca";

export default node;
