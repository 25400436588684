/**
 * @generated SignedSource<<f0ff58fdd304bcfb0ecbff597edd7d12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavHeader_user$data = {
  readonly conversationSummary: {
    readonly totalUnreadConversationCount: number | null;
    readonly unreadActiveOffers: number | null;
  } | null;
  readonly isVerifiedTrade: boolean | null;
  readonly isVip: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavCartContainer_user">;
  readonly " $fragmentType": "MobileNavHeader_user";
};
export type MobileNavHeader_user$key = {
  readonly " $data"?: MobileNavHeader_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavHeader_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNavHeader_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerifiedTrade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Summary",
      "kind": "LinkedField",
      "name": "conversationSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalUnreadConversationCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadActiveOffers",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavCartContainer_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a2b651057ee9dac3535bd9add80301d1";

export default node;
