import get from 'lodash.get';
import { Suspense } from 'react';
import * as React from 'react';
import { bool, func, object } from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { actionCreators } from '../../actions';
import { connect } from 'react-redux';
import { MobileNav } from '../MobileNav/MobileNav';
import { getBuyerId } from 'dibs-cookie-jar';
import {
    AUTH_CLICK_LOGIN,
    AUTH_CLICK_REGISTER,
    AUTH_CLICK_LOGOUT,
} from '../MobileNav/utils/mobileNavConstants';
import { ACTION_LOGIN, ACTION_REGISTER } from '../AccountNav/accountConstants';
import * as userTracking from '../../utils/userTracking';
import authModalLoader from '../../utils/AuthModalLoader';
import { getSessionCurrency } from '../../utils/preferredCurrency';
import {
    hasValidUserSessionGeoInfo,
    getUserSessionCountryCode,
    getUserZipCode,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import { getLocalHistoryItemIds } from 'dibs-recent-history/exports/getLocalHistoryItemIds';
import { MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS } from 'dibs-recent-history/exports/constants';
import { loginUserWithEmailLoginToken } from '../../helpers/loginUserWithEmailLoginTokenHelper';
import { getEmailLoginToken } from '../../helpers/getEmailLoginTokenHelper';

const MobileVipCuratedFolderCallout = React.lazy(() =>
    import(
        /* webpackChunkName: "MobileVipCuratedFolderCallout" */ '../VipCuratedFolderTooltip/VipCuratedFolderTooltip'
    )
);
class MobileNavWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isClient: false,
            fragmentVariables: {
                userId: '',
                userIds: [],
                hasUserId: false,
                fetchRegionalInfo: false,
                zipCode: '',
                hasSentLoginMutation: false,
            },
        };
        this.onAuthClick = this.onAuthClick.bind(this);
    }

    componentDidMount() {
        this.refetchViewer();
    }

    async componentDidUpdate(prevProps) {
        const { hasSentLoginMutation } = this.state;
        const {
            viewer,
            updateUserState,
            relay: { environment },
        } = this.props;
        const { user } = viewer || {};
        const { user: prevUser } = prevProps.viewer || {};
        const emailLoginToken = getEmailLoginToken();

        if (updateUserState && !prevProps.updateUserState) {
            this.refetchViewer();
        }
        if (user?.isVerifiedTrade && !prevUser) {
            this.props.updateUserTypeTrade();
        }
        if (user?.isVip && !prevUser) {
            this.props.updateUserTypeVip();
        }

        const userId = getBuyerId(document.cookie) || '';
        const shouldLoginUser = !hasSentLoginMutation && !userId && !!emailLoginToken;

        if (shouldLoginUser) {
            this.setState({ hasSentLoginMutation: true });
            const refetchViewer = () => {
                this.refetchViewer();
            };
            loginUserWithEmailLoginToken(environment, emailLoginToken, refetchViewer);
        }
    }

    refetchViewer() {
        if (typeof document === 'undefined') {
            return;
        }
        const userId = getBuyerId(document.cookie) || '';
        const hasUserId = !!userId;
        const fetchRegionalInfo =
            (!hasUserId && !getSessionCurrency()) || !hasValidUserSessionGeoInfo();
        const localRecentHistoryItemIds = getLocalHistoryItemIds({ isClient: true });

        const fragmentVariables = {
            ...this.state.fragmentVariables,
            hasUserId,
            userId,
            userIds: [userId],
            isClient: true,
            fetchRegionalInfo,
            zipCode: getUserZipCode(),
            userCountryCode: getUserSessionCountryCode(),
            localRecentHistoryItemIds,
            fetchUserRecentHistoryItem:
                localRecentHistoryItemIds.length < MAX_CAROUSEL_RECENTLY_VIEWED_ITEMS,
        };

        this.setState({ isClient: true, fragmentVariables }, () => {
            this.props.relay.refetch(this.state.fragmentVariables);
        });
    }

    onAuthClick(clickType) {
        switch (clickType) {
            case AUTH_CLICK_LOGIN:
                authModalLoader
                    .show({
                        relayEnvironment: this.props.relay.environment,
                        email: localStorage.getItem('userEmail'),
                        action: ACTION_LOGIN,
                        ga: { label: 'mobile nav login' },
                    })
                    .then(this.props.closeMobileMenu);
                break;
            case AUTH_CLICK_REGISTER:
                authModalLoader
                    .show({
                        relayEnvironment: this.props.relay.environment,
                        email: localStorage.getItem('userEmail'),
                        action: ACTION_REGISTER,
                        ga: { label: 'mobile nav register' },
                    })
                    .then(this.props.closeMobileMenu);
                break;
            case AUTH_CLICK_LOGOUT:
                this.props.logoutUser();
                break;
        }
    }

    render() {
        const {
            viewer,
            isVipCuratedFolderTooltipVisible,
            hideSearchBar,
            relay,
            initialTrackingFired,
            setInitialTrackingFired,
        } = this.props;
        const { user = null } = viewer;
        const regionalInfo = get(viewer, 'regionalInfo') || null;
        const { fragmentVariables, isClient } = this.state;
        const { isFullWidth, fetchRegionalInfo, userIds, userId } = fragmentVariables;
        const hasRootPendingRefetch = (userId && !user) || (fetchRegionalInfo && !regionalInfo);

        return (
            <>
                <MobileNav
                    fetchRegionalInfo={fetchRegionalInfo}
                    hideSearchBar={hideSearchBar}
                    isClient={isClient}
                    isFullWidth={isFullWidth}
                    onAuthClick={this.onAuthClick}
                    viewer={viewer}
                    user={user}
                    userIds={userIds}
                    regionalInfo={regionalInfo}
                    userId={userId}
                    relayEnvironment={relay.environment}
                    hasRootPendingRefetch={hasRootPendingRefetch}
                />
                {isVipCuratedFolderTooltipVisible ? (
                    <React.Suspense fallback="">
                        <MobileVipCuratedFolderCallout isMobile />
                    </React.Suspense>
                ) : null}
                {viewer.relayIsClient && (
                    <Suspense fallback={''}>
                        <userTracking.UserTrackingInit
                            viewer={viewer}
                            user={user}
                            environment={relay.environment}
                            initialTrackingFired={initialTrackingFired}
                            setInitialTrackingFired={setInitialTrackingFired}
                        />
                    </Suspense>
                )}
            </>
        );
    }
}

MobileNavWrapper.propTypes = {
    initialTrackingFired: bool,
    updateUserTypeTrade: func,
    updateUserTypeVip: func,
    updateUserState: bool,
    setInitialTrackingFired: func,
    logoutUser: func.isRequired,
    closeMobileMenu: func.isRequired,
    viewer: object.isRequired,
    user: object,
    relay: object.isRequired,
    hideSearchBar: bool,
    dispatch: func,
    isFullWidth: bool,
    isVipCuratedFolderTooltipVisible: bool,
};

const mapStateToProps = ({ header }) => ({
    updateUserState: header.updateUserState,
    logoutUser: header.logoutUser || (() => {}),
    isFullWidth: header.isMobile,
    registrationTriggerTestFired: header.registrationTriggerTestFired,
    initialTrackingFired: header.initialTrackingFired,
    isVipCuratedFolderTooltipVisible: header.isVipCuratedFolderTooltipVisible,
    hideSearchBar: header.hideSearchBar,
});

const mapDispatchToProps = dispatch => {
    return {
        updateUserTypeTrade: () => {
            dispatch(actionCreators.setUserTypeTrade(true));
        },
        updateUserTypeVip: () => {
            dispatch(actionCreators.setUserTypeVip(true));
        },
        closeMobileMenu: () => {
            dispatch(actionCreators.toggleMobileMenu(false));
        },
        setInitialTrackingFired: () => {
            dispatch(actionCreators.setInitialTrackingFired());
        },
        setRegistrationTriggerABTestFired: () => {
            dispatch(actionCreators.setRegistrationTriggerABTestFired());
        },
    };
};

export default createRefetchContainer(
    connect(mapStateToProps, mapDispatchToProps)(MobileNavWrapper),
    {
        viewer: graphql`
            fragment MobileNavWrapper_viewer on Viewer {
                # component's isClient and relay's $isClient is out of sync
                # this var can be used to ensure we have issued a refetch and all the fragments are in place
                relayIsClient: __typename @include(if: $isClient)
                ...MobileNav_viewer
                ...userTracking_viewer @defer @include(if: $isClient)
                user(userId: $userId) @include(if: $hasUserId) {
                    ...userTracking_user @defer @include(if: $isClient)
                    ...MobileNav_user
                    isVerifiedTrade
                    isVip
                }
                regionalInfo(userId: $userId, countryCode: $userCountryCode, zipCode: $zipCode)
                    @include(if: $fetchRegionalInfo) {
                    ...MobileNav_regionalInfo
                    ...userTracking_regionalInfo @relay(mask: false)
                }
            }
        `,
    },
    graphql`
        query MobileNavWrapperRefetchQuery(
            $userId: String!
            $userIds: [String!]
            $isClient: Boolean!
            $hasUserId: Boolean!
            $previewKey: String = ""
            $variantId: String = ""
            $fetchRegionalInfo: Boolean!
            $zipCode: String
            $userCountryCode: String = ""
            $localRecentHistoryItemIds: [String] = []
            $fetchUserRecentHistoryItem: Boolean = false
            $fetchTrackingGdprInfo: Boolean = true
        ) {
            viewer {
                ...MobileNavWrapper_viewer
            }
        }
    `
);
