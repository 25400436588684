import { Component } from 'react';
import { bool, element } from 'prop-types';
import styles from './scrollLock.scss';

export default class ScrollLock extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isLocked !== this.props.isLocked) {
            this.toggleBodyScroll(nextProps.isLocked);
        }
    }

    saveScrollPosition() {
        this.scrollY = window.pageYOffset;
    }

    restoreScrollPosition() {
        return window.scrollTo(0, this.scrollY);
    }

    toggleBodyScroll(isLocked) {
        const body = document.body;
        if (isLocked) {
            this.saveScrollPosition();
            body.classList.add(styles.noScroll);
            /**
             * This component is primaraly used with an overlay. There are cases where the overlay can be triggered
             * by an input element. In that case iOS safari opens up the keyboard and in certain cases introduces
             * a scrollbar. This causes the overlay element to scroll down. The goal is to keep the scroll position
             * always at the top and we force it by calling `scrollTo`.
             */
            window.scrollTo(0, 0);
        } else {
            body.classList.remove(styles.noScroll);
            this.restoreScrollPosition();
        }
    }

    render() {
        return this.props.children || null;
    }
}

ScrollLock.propTypes = {
    isLocked: bool,
    children: element,
};
