import { graphql } from 'react-relay/legacy';

export default graphql`
    query mobileNavWrapperRootQuery(
        $hasUserId: Boolean!
        $userId: String!
        $userIds: [String!]
        $isClient: Boolean!
        $fetchRegionalInfo: Boolean!
        $previewKey: String = ""
        $variantId: String = ""
        $zipCode: String!
        $userCountryCode: String = ""
        $localRecentHistoryItemIds: [String] = []
        $fetchUserRecentHistoryItem: Boolean = false
        $fetchTrackingGdprInfo: Boolean = true
    ) {
        viewer {
            ...MobileNavWrapper_viewer
        }
    }
`;

export const initialVariables = {
    hasUserId: false,
    fetchRegionalInfo: false,
    userId: '',
    userIds: [],
    isClient: false,
    zipCode: '',
};
