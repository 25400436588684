/**
 * @generated SignedSource<<d3e40ad60456b9d46dd7ce32cfba88da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavMenu_user$data = {
  readonly conversationSummary: {
    readonly totalUnreadConversationCount: number | null;
    readonly unreadActiveOffers: number | null;
  } | null;
  readonly ethereumWalletAddress: {
    readonly address: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavMenuBody_user">;
  readonly " $fragmentType": "MobileNavMenu_user";
};
export type MobileNavMenu_user$key = {
  readonly " $data"?: MobileNavMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNavMenu_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Summary",
      "kind": "LinkedField",
      "name": "conversationSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalUnreadConversationCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadActiveOffers",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EthereumAddressType",
      "kind": "LinkedField",
      "name": "ethereumWalletAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavMenuBody_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "50d1f67bc8a4c261d14b1d3b8607f58f";

export default node;
