/**
 * @generated SignedSource<<77f49d3ac158c22200b966056ac0dbf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavSearchBar_viewer$data = {
  readonly isMobileRecentSearchUiDisabled: boolean | null;
  readonly user?: {
    readonly " $fragmentSpreads": FragmentRefs<"getRecentHistory_user">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useSearchSuggestions_viewer">;
  readonly " $fragmentType": "MobileNavSearchBar_viewer";
};
export type MobileNavSearchBar_viewer$key = {
  readonly " $data"?: MobileNavSearchBar_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavSearchBar_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "hasQuery"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNavSearchBar_viewer",
  "selections": [
    {
      "alias": "isMobileRecentSearchUiDisabled",
      "args": [
        {
          "kind": "Literal",
          "name": "key",
          "value": "disableMobileSearchRecentUi"
        }
      ],
      "kind": "ScalarField",
      "name": "isEnabled",
      "storageKey": "isEnabled(key:\"disableMobileSearchRecentUi\")"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "hasQuery",
          "variableName": "hasQuery"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSearchSuggestions_viewer"
    },
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "Defer",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "getRecentHistory_user"
                }
              ]
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "7a5cfdd7b8a00b64f02592717e2d86fe";

export default node;
