import { object, func, string, number, oneOfType } from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { FormattedMessage } from 'dibs-react-intl';
import { Button } from 'dibs-elements/exports/Button';

import {
    AUTH_CLICK_LOGIN,
    AUTH_CLICK_REGISTER,
    AUTH_CLICK_LOGOUT,
} from '../utils/mobileNavConstants';

import styles from './mobileNavAuthButtonsStyles.scss';

function AuthButton(props) {
    return (
        <Button
            type="secondary"
            size="medium"
            className={styles.button}
            onClick={() => props.onAuthClick(props.clickAction)}
            dataTn={props.dataTn}
        >
            {props.children}
        </Button>
    );
}

AuthButton.propTypes = {
    onAuthClick: func.isRequired,
    clickAction: oneOfType([number, string]),
    dataTn: string,
    children: object,
};

export function MobileNavAuthButtons({ user, onAuthClick }) {
    if (user) {
        return (
            <div className={styles.container}>
                <div className={styles.flexContainer}>
                    <AuthButton
                        onAuthClick={onAuthClick}
                        clickAction={AUTH_CLICK_LOGOUT}
                        dataTn={'mobile-nav-logout'}
                    >
                        <FormattedMessage id="mobile.navigation.logout" defaultMessage="Log Out" />
                    </AuthButton>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.flexContainer}>
                    <AuthButton
                        onAuthClick={onAuthClick}
                        clickAction={AUTH_CLICK_LOGIN}
                        dataTn={'mobile-nav-login'}
                    >
                        <FormattedMessage id="mobile.navigation.login" defaultMessage="Login" />
                    </AuthButton>
                    <AuthButton
                        onAuthClick={onAuthClick}
                        clickAction={AUTH_CLICK_REGISTER}
                        dataTn={'mobile-nav-register'}
                    >
                        <FormattedMessage
                            id="mobile.navigation.register"
                            defaultMessage="Register"
                        />
                    </AuthButton>
                </div>
            </div>
        );
    }
}

export default createFragmentContainer(MobileNavAuthButtons, {
    user: graphql`
        fragment MobileNavAuthButtons_user on User {
            serviceId
        }
    `,
});

MobileNavAuthButtons.propTypes = {
    onAuthClick: func.isRequired,
    user: object,
};
