/**
 * @generated SignedSource<<01395b06395271bf9672190dbddee121>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNavMenuBody_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DisplaySettingsModal_user" | "MobileNavAuthButtons_user">;
  readonly " $fragmentType": "MobileNavMenuBody_user";
};
export type MobileNavMenuBody_user$key = {
  readonly " $data"?: MobileNavMenuBody_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavMenuBody_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNavMenuBody_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavAuthButtons_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DisplaySettingsModal_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "fccb7a576e3e832231fdcb845779d078";

export default node;
