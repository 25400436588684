/**
 * @generated SignedSource<<268ca0214f91b145e82831fea9ccf952>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MobileNav_user$data = {
  readonly hasLoggedInWithApp: boolean | null;
  readonly preferences: {
    readonly currency: Currency | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavHeader_user" | "MobileNavMenu_user">;
  readonly " $fragmentType": "MobileNav_user";
};
export type MobileNav_user$key = {
  readonly " $data"?: MobileNav_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNav_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNav_user",
  "selections": [
    {
      "alias": "hasLoggedInWithApp",
      "args": [
        {
          "kind": "Literal",
          "name": "deviceType",
          "value": "IOS_APP"
        }
      ],
      "kind": "ScalarField",
      "name": "hasLoggedInWithDeviceType",
      "storageKey": "hasLoggedInWithDeviceType(deviceType:\"IOS_APP\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavMenu_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavHeader_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f8ac9b176482708a9f88ccdd85e78195";

export default node;
