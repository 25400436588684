import { Fragment } from 'react';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
} from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;
const { STEP_INTERACTION_GLOBAL_NAV_MOBILE_DRILLDOWN_CLICK } = stepInteractionConstants;
import { func, string, bool } from 'prop-types';
import styles from './MobileNavDrilldownItem.scss';
import { FormattedNumber } from 'dibs-react-intl';
import { SeoLink } from 'dibs-seo/exports/SeoLink';
import slugify from 'dibs-slugify/exports/slugify';
import { Link } from 'dibs-elements/exports/Link';
import { Badge } from 'dibs-elements/exports/Badge';

const trackMenuEvent = ({ parentSectionName, sectionName, linkName }, event = null) => {
    const label = `${
        parentSectionName ? `${parentSectionName} | ` : ''
    }${sectionName} | ${linkName}`.toLowerCase();
    trackEvent(
        {
            category: 'navigation',
            action: 'header navigation clicked',
            label,
            eventName: EVENT_NAVIGATION,
            interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
            step_interaction_name: STEP_INTERACTION_GLOBAL_NAV_MOBILE_DRILLDOWN_CLICK,
            trigger: label,
        },
        event
    );
};

/**
 * Anchor type is intended to be used for cases where:
 * - We create links on the client side, so there's no easy way to hook up to relay.
 * - These links are only visible when the user is logged in.
 */
export const LINK_ELEMENT_TYPE_ANCHOR = 'anchor';
export const LINK_ELEMENT_TYPE_BROWSE_CMS = 'browseCms';

function MobileNavDrilldownItem(props) {
    const {
        parentSectionName,
        sectionName,
        name,
        linkData,
        count,
        type = LINK_ELEMENT_TYPE_BROWSE_CMS,
        onItemClick = () => {},
        hideItem,
    } = props;
    const { path } = linkData;

    function onClickHandler(e) {
        e.persist(); // allow onItemClick to handle event
        onItemClick({ path, name }, e);

        if (sectionName) {
            if (type === LINK_ELEMENT_TYPE_ANCHOR) {
                trackMenuEvent({ sectionName, linkName: name }, e);
            } else if (parentSectionName) {
                trackMenuEvent({ parentSectionName, sectionName, linkName: name }, e);
            }
        }
    }

    const dataTn = `drilldown-${slugify(name)}`;
    const kids = (
        <Fragment>
            <span>{name}</span>
            {!!count && (
                <span data-tn={`drilldown-${slugify(name)}-count`}>
                    <Badge type="alert">
                        <FormattedNumber value={count} />
                    </Badge>
                </span>
            )}
        </Fragment>
    );
    if (hideItem && linkData?.path === '/seller-directory/') {
        return null;
    } else if (type === LINK_ELEMENT_TYPE_ANCHOR) {
        return (
            <Link onClick={onClickHandler} href={path} dataTn={dataTn} className={styles.item}>
                {kids}
            </Link>
        );
    } else {
        return (
            <SeoLink
                onClick={onClickHandler}
                className={styles.item}
                dataTn={dataTn}
                linkData={linkData}
            >
                {kids}
            </SeoLink>
        );
    }
}

MobileNavDrilldownItem.propTypes = {
    parentSectionName: string,
    sectionName: string,
    onItemClick: func,
    hideItem: bool,
};

export default MobileNavDrilldownItem;
