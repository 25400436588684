/**
 * @generated SignedSource<<c152f958dfefb165ffde50ee33f4563c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MobileNav_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MobileNavHeader_viewer" | "MobileNavMenu_viewer" | "MobileNavSearchBar_viewer">;
  readonly " $fragmentType": "MobileNav_viewer";
};
export type MobileNav_viewer$key = {
  readonly " $data"?: MobileNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"MobileNav_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MobileNav_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavHeader_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavMenu_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileNavSearchBar_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f8d120b5433ad3ca9a6929378ed0d6eb";

export default node;
