import { type FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'dibs-react-intl';
import { sessionStorage } from 'dibs-browser-storage';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import CloseIcon from 'dibs-icons/exports/legacy/Close';
import { Button } from 'dibs-elements/exports/Button';
import { Link } from 'dibs-elements/exports/Link';
import LogoMonogram from 'dibs-icons/exports/legacy/LogoMonogram';
import appLinks from '../../helpers/appLinks';
import { toggleAppBanner } from '../../actions/appBannerActions';
import { mobileAppBannerTracking } from '../MobileAppBanner/helpers';
import { APP_BANNER_STORAGE_KEY } from './constants';

import styles from './MobileAppBanner.scss';

export const MobileAppBanner: FC = () => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(true);
    useEffect(() => {
        mobileAppBannerTracking('displayed');
    }, []);

    const handleClick = (): void => {
        window.open(handleLocaleUrl(appLinks.MW_APP_DOWNLOAD_BANNER, GLOBAL_CLIENT_ONLY_LOCALE));
        mobileAppBannerTracking('expanded');
    };

    const handleClose = (): void => {
        sessionStorage.setItem(APP_BANNER_STORAGE_KEY, true);
        dispatch(toggleAppBanner(false));
        setIsOpen(false);
        mobileAppBannerTracking('closed');
    };

    return isOpen ? (
        <div className={styles.bannerContainer}>
            <Link className={styles.closeIcon} onClick={handleClose}>
                <CloseIcon />
            </Link>
            <div className={styles.appIconContainer}>
                <LogoMonogram />
            </div>
            <div className={styles.description}>
                <div className={styles.appTitle}>
                    <FormattedMessage id="dbl.mobileAppBanner.title" defaultMessage="1stDibs App" />
                </div>
                <div className={styles.appSubtitle}>
                    <FormattedMessage
                        id="dbl.mobileAppBanner.description"
                        defaultMessage="Browsing made beautiful"
                    />
                </div>
            </div>
            <Button
                className={styles.button}
                onClick={handleClick}
                dataTn="mobile-footer-app-banner-callout"
                size="small"
                type="secondary"
            >
                <FormattedMessage id="dbl.mobileAppBanner.openApp" defaultMessage="Open app" />
            </Button>
        </div>
    ) : null;
};
