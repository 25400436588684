import { useIntl, defineMessages } from 'dibs-react-intl';
import { func, bool, object, string } from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import classnames from 'classnames';
import styles from './mobileNavHeader.scss';
import Close from 'dibs-icons/exports/legacy/Close';
import HamburgerIcon from 'dibs-icons/exports/legacy/HamburgerMenu';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { Link } from 'dibs-elements/exports/Link';
import { MobileNavLogo } from '../MobileNavIcons/MobileNavLogo';
import MobileNavFavoriteIcon from '../MobileNavIcons/MobileNavFavorite';
import MobileNavCartContainer from '../MobileNavIcons/MobileNavCartContainer';
import MobileNavDisplayAmount from '../MobileNavDisplayAmount/MobileNavDisplayAmount';
import { SEARCH_BAR_ID } from '../MobileNavSearchBar/MobileNavSearchBar';
import get from 'lodash.get';

const AMOUNT_MAX_MESSAGES = 99;
const messages = defineMessages({
    openLabel: { id: 'dbl.MobileNavHeader.openLabel', defaultMessage: 'open menu' },
    closeLabel: { id: 'dbl.MobileNavHeader.closeLabel', defaultMessage: 'close menu' },
});
// to make everything but the nav inert, select the top-most containers
// outside of the header. include the search bar bc the nav drawer opens
// over it.
const PAGE_CONTENT_SELECTORS = ['footer', 'main', '#back-to-top-button', `#${SEARCH_BAR_ID}`].join(
    ', '
);
function removeInert() {
    if (document) {
        Array.from(document.querySelectorAll(PAGE_CONTENT_SELECTORS)).forEach(node => {
            node.removeAttribute('inert');
        });
    }
}
// inert removes interactivity from an element and its children, just like how
// an alert or dialog popup does. we can use if bc polyfill wicg-inert is part
// of dibs-vendor. primarily for use with modals.
// see for https://wicg.github.io/inert/explainer.html#background for more info
function addInert() {
    if (document) {
        Array.from(document.querySelectorAll(PAGE_CONTENT_SELECTORS)).forEach(node => {
            node.setAttribute('inert', 'true');
        });
    }
}
export function MobileNavHeader(props) {
    const intl = useIntl();
    const {
        isMobileMenuOpen,
        user,
        viewer,
        containerClassName,
        showUnreadMessageCount = false,
        onActionClick = () => {},
    } = props;
    const containerClass = classnames(styles.container, {
        [containerClassName]: !!containerClassName,
    });
    const actionIconWrapperClass = classnames(styles.icon, styles.actionIconWrapper);
    const iconClass = classnames(styles.actionIcon, {
        [styles.closeIcon]: isMobileMenuOpen,
    });
    const Icon = isMobileMenuOpen ? Close : HamburgerIcon;
    const unreadMessageCount = get(user, 'conversationSummary.totalUnreadConversationCount') || 0;
    const activePrivateOffer = get(user, 'conversationSummary.unreadActiveOffers') || 0;
    const showDisplayAmount = showUnreadMessageCount && !!unreadMessageCount;
    function handleMenuToggle() {
        // add inert to non-nav elements when nav drawer
        if (isMobileMenuOpen) {
            removeInert();
        } else {
            addInert();
        }
        onActionClick();
    }
    return (
        <div className={containerClass}>
            <Link className={actionIconWrapperClass} onClick={handleMenuToggle}>
                {showDisplayAmount && (
                    <i className={styles.messages}>
                        <MobileNavDisplayAmount
                            className={styles.messagesCount}
                            amount={unreadMessageCount + activePrivateOffer}
                            max={AMOUNT_MAX_MESSAGES}
                        />
                        <span className={styles.visuallyHidden}>
                            {intl.formatMessage(
                                isMobileMenuOpen ? messages.openLabel : messages.closeLabel
                            )}
                        </span>
                    </i>
                )}
                <Icon className={iconClass} />
            </Link>
            <SeoLink className={styles.logo} linkData={{ path: '/' }}>
                <MobileNavLogo user={user} />
            </SeoLink>
            <div className={styles.iconWrapper}>
                <MobileNavFavoriteIcon className={styles.icon} />
                <MobileNavCartContainer user={props.user} className={styles.icon} viewer={viewer} />
            </div>
        </div>
    );
}

MobileNavHeader.propTypes = {
    showUnreadMessageCount: bool,
    containerClassName: string,
    onActionClick: func,
    isMobileMenuOpen: bool,
    viewer: object,
    user: object,
};

export default createFragmentContainer(MobileNavHeader, {
    viewer: graphql`
        fragment MobileNavHeader_viewer on Viewer {
            ...MobileNavCartContainer_viewer
        }
    `,
    user: graphql`
        fragment MobileNavHeader_user on User {
            isVerifiedTrade
            isVip
            conversationSummary {
                totalUnreadConversationCount
                unreadActiveOffers
            }
            ...MobileNavCartContainer_user
        }
    `,
});
