import { bool, object } from 'prop-types';
import { connect } from 'react-redux';
import MobileLogo from 'dibs-logo/exports/MobileLogo';

const MobileNavLogoComponent = ({ user, isUserTypeTrade = false, isUserTypeVip }) => (
    <MobileLogo
        fillColor="pitchBlack"
        user={user}
        isUserTypeTrade={isUserTypeTrade}
        isUserTypeVip={isUserTypeVip}
    />
);

function mapStateToProps(state) {
    return {
        isUserTypeTrade: state.header.isUserTypeTrade,
        isUserTypeVip: state.header.isUserTypeVip,
    };
}

MobileNavLogoComponent.propTypes = {
    isUserTypeTrade: bool,
    isUserTypeVip: bool,
    user: object,
};

export const MobileNavLogo = connect(mapStateToProps)(MobileNavLogoComponent);
