import { Fragment, useEffect } from 'react';
import classNames from 'classnames';
import { func, string, object, array } from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

import MobileNavAuthButtons from '../MobileNavAuthButtons/MobileNavAuthButtons';
import MobileNavDrilldownItem from '../MobileNavDrilldown/MobileNavDrilldownItem';
import DisplaySettingsModalLazy from '../../DisplaySettingsModal/DisplaySettingsModalLazy';
import { SITE_NAV_AB_TEST_NAME } from '../../../siteNavAbTestConfig';

import styles from './mobileNavMenuBody.scss';

function MobileNavMenuBody(props) {
    const {
        viewer,
        user,
        modules,
        sectionsInfo: { title, additionalSections },
        className = '',
        onItemClick,
        onAuthClick,
    } = props;
    const sections = [
        {
            title,
            items: modules,
        },
        ...additionalSections,
    ];

    const titleClassName = classNames(styles.item, styles.sectionTitle);

    useEffect(() => {
        trackAbTestV2Variant(SITE_NAV_AB_TEST_NAME);
    }, []);

    return (
        <div className={className} data-tn="mobile-nav-menu-body">
            <DisplaySettingsModalLazy
                placement="mobileHeader"
                viewer={viewer}
                user={user || null}
            />
            {sections.map(section => {
                return (
                    <Fragment key={section.title}>
                        <div className={titleClassName}>{section.title}</div>
                        {section.items.map(({ name, linkData, count, type }) => (
                            <MobileNavDrilldownItem
                                type={type}
                                key={name}
                                sectionName={section.title}
                                parentSectionName={section.name}
                                onItemClick={onItemClick}
                                name={name}
                                linkData={linkData}
                                count={count}
                            />
                        ))}
                    </Fragment>
                );
            })}
            <MobileNavAuthButtons user={user} onAuthClick={onAuthClick} />
        </div>
    );
}

MobileNavMenuBody.propTypes = {
    className: string,
    sectionsInfo: object,
    modules: array,
    onItemClick: func.isRequired,
    onAuthClick: func.isRequired,
    user: object,
    viewer: object,
};

export default createFragmentContainer(MobileNavMenuBody, {
    viewer: graphql`
        fragment MobileNavMenuBody_viewer on Viewer {
            ...DisplaySettingsModal_viewer
        }
    `,
    user: graphql`
        fragment MobileNavMenuBody_user on User {
            ...MobileNavAuthButtons_user
            ...DisplaySettingsModal_user
        }
    `,
    modules: graphql`
        fragment MobileNavMenuBody_modules on NavigationModule @relay(plural: true) {
            name
            linkData {
                path
                ...SeoLink_linkData
            }
        }
    `,
});
